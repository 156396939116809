import React from "react";
import Container from "../../components/Container";
import ConnectLogoFull from "../../assets/connect_logo_full.png";
import NDPCLogo from "../../assets/ndpc_logo.png";
const Footer = () => {
  return (
    <div className="w-full  bg-[#d40000] text-center text-white overflow-clip">
      <Container className="flex  py-[40px] text-white md:flex-row flex-col">
        <div className="flex  flex-1 w-full">
          <div className="flex-2 flex flex-col ">
            {/* <div className="h-[40px] w-[100px] md:w-[200px]">
              <img src={ConnectLogoFull} />
            </div> */}
            <ul className="flex flex-col py-[50px] gap-y-[10px] w-[100%] text-[14px]">
              <li>
                No 12, Legina Phase 1 <br />
                Lagos State, Nigeria{" "}
              </li>
            </ul>
          </div>
          <div className="flex-1 ml-[50px]">
            <div>
              <p className="py-[40px] font-bold text-[20px]">Company</p>
              <ul className="flex flex-col gap-y-[20px] text-[14px]">
                <li>About us</li>
                <li>Contact us</li>
                <li>Testimonials</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-between w-full">
          <div className="flex-1 md:ml-[50px] w-full">
            <div className="w-full">
              <p className="py-[40px] font-bold text-[20px]">Support</p>
              <ul className="flex flex-col gap-y-[20px] text-[14px]">
                <li>Term of service</li>
                <li>Legal</li>
                <li>Privacy policy</li>
              </ul>
            </div>
          </div>
          <div className="flex-1 ml-[50px] w-full">
            <div className="w-full">
              <p className="py-[40px] font-bold text-[20px]">Stay up to date</p>
              <ul className="flex flex-col gap-y-[20px] text-[14px]">
                <li>09075787095</li>
                <li className="text-wrap ">support@connectsphere.com.ng</li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex items-center flex-col pb-5">
        <p className="text-xs md:text-sm">Certified by:</p>
        <div className="w-[200px] h-[100px]">
          <img src={NDPCLogo} className="object-contain w-full h-full" />
        </div>
        <p className="text-xs md:text-sm">
          All right reserved. &copy; {new Date().getFullYear()} connectsphere.com.ng
        </p>
      </div>
    </div>
  );
};

export default Footer;
