import React from "react";
import NavBar from "../../components/NavBar";
import { ReactSVG } from "react-svg";
import landingPageBg from "../../assets/landing_page_bg.png";
import connectDevices from "../../assets/connect_devices.png";
import Container from "../../components/Container";
import connectSmallLogo from "../../assets/connect_small_logo.png";
import GooglePlayLogo from "../../assets/google_play.svg";
import AppStoreLogo from "../../assets/appstore.png";
import MobilePhone from "../../assets/phone_image.png";
import ConnectPayOnboard from "../../assets/connectpay_onboard.png";

const LandingPage = () => {
  return (
    <div className="h-[100vh] w-[100vw] md:pt-[80px] pt-[40px] bg-connect_bg bg-cover bg-center bg-no-repeat">
      <Container className="flex py-[50px] h-full md:flex-row flex-col">
        <div className="flex-1 font-[Mulish] gap-y-[50px] md:py-[25px] py-[20px]">
          <p className="font-black md:text-[45px] text-[25px]">
            Get the convenience of <br /> transacting with
          </p>
          <div className="md:h-[100px] h-[50px] w-[full] flex flex-col items-start justify-start">
            <img src={ConnectPayOnboard} className="object-contain  h-full" />
          </div>
          <p className="font-medium my-5 md:my-10 md:text-[14px] text-[12px]">
            Streamline your payments and enjoy seamless transactions with ConnectPay.{" "}
            <br />
            From airtime to bills, we bring convenience to your fingertips.
          </p>
          <div className="flex gap-x-3 md:gap-x-10">
            <div className="flex w-[150px] md:w-[180px] h-[50px] md:h-[60px] bg-black text-white justify-center items-center rounded-xl cursor-pointer">
              <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
                <img src={GooglePlayLogo} className="object-contain w-full h-full" />
              </div>
              <div className="flex flex-col flex-1">
                <p className="text-xs">Download on</p>
                <p className="font-semibold md:text-[20px] text-[14px]">Play Store</p>
              </div>
            </div>
            <div className="flex w-[150px] md:w-[180px] h-[50px] md:h-[60px] bg-black text-white justify-center items-center rounded-xl cursor-pointer">
              <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
                <img src={AppStoreLogo} className="object-contain w-full h-full" />
              </div>
              <div className="flex flex-col flex-1">
                <p className="text-xs">Download on</p>
                <p className="font-semibold md:text-[20px] text-[14px]">App Store</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 w-full h-full">
          <img src={MobilePhone} className="object-contain w-full h-full" />
        </div>
      </Container>
    </div>
  );
};

export default LandingPage;
