import React from "react";
import LandingPage from "./LandingPage";
import Services from "./Services";
import NavBar from "../../components/NavBar";
import OurBillers from "./OurBillers";
import Growth from "./Growth";
import Testimonials from "./Testimonials";
import Footer from "./Footer";

const Onboarding = () => {
  return (
    <div className="w-[100vw] overflow-clip flex flex-col items-center">
      <NavBar />
      <LandingPage />

      <OurBillers />
      <Services />

      <Testimonials />
      <Growth />
      <Footer />
    </div>
  );
};

export default Onboarding;
