import React from "react";
import Container from "../../components/Container";
import { IoPerson } from "react-icons/io5";

const Testimonials = () => {
  return (
    <div className="w-full ">
      <Container className="text-center pt-[80px]">
        <p className="font-[Bestigia] text-[30px] text-brown">
          What our customers say about us
        </p>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-10 gap-y-10 py-[50px] ">
          <div className="flex-1 md:h-[300px] h-[200px] relative">
            <div className="absolute bottom-0 md:h-[260px] h-[150px]  bg-brown  w-full rounded-3xl"></div>
            <div className="absolute left-[50%] translate-x-[-50%] w-[80px] h-[80px] rounded-full bg-gold  flex justify-center items-center">
              <IoPerson size={35} className="text-brown" />
            </div>
            <div className="absolute top-[100px] px-[10px] md:px-[80px] md:leading-10 leading-5 text-white">
              I really love connectsphere for their affordable prices and amazing customer
              support.
            </div>
          </div>
          <div className="flex-1 md:h-[300px] h-[200px] relative">
            <div className="absolute bottom-0 md:h-[260px] h-[150px]  bg-brown w-full rounded-3xl"></div>
            <div className="absolute left-[50%] translate-x-[-50%] w-[80px] h-[80px] rounded-full bg-gold  flex justify-center items-center">
              <IoPerson size={35} className="text-brown" />
            </div>
            <div className="absolute top-[100px] px-[10px] md:px-[80px] pb-3 md:leading-8 leading-5 text-white">
              The data plans at connectsphere can only be seen at connectsphere. I'm
              surprised by how many things they are offering at affordable prices.
            </div>
          </div>
          <div className="flex-1 md:h-[300px] h-[200px] relative">
            <div className="absolute bottom-0 md:h-[260px] h-[150px]  bg-brown w-full rounded-3xl"></div>
            <div className="absolute left-[50%] translate-x-[-50%] w-[80px] h-[80px] rounded-full bg-gold  flex justify-center items-center">
              <IoPerson size={35} className="text-brown" />
            </div>
            <div className="absolute top-[100px] px-[10px] md:px-[80px] text-white  md:leading-10 leading-5">
              Every category has a king. Connectsphere is the king here!
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
