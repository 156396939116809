import ConnectLogo from "../assets/connect_logo.png";

import React, { useState } from "react";
import Container from "./Container";
import Hamburger from "hamburger-react";
import { motion } from "framer-motion";
import Backdrop from "@mui/material/Backdrop";

const NavBar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Container className="flex items-center justify-between fixed top-0 z-20 backdrop-blur-md bg-white/40 w-full">
      <div className="my-[30px] h-[30px] md:w-[180px] w-[150px] flex items-center justify-center">
        <img src={ConnectLogo} className="object-contain" />
      </div>
      <div className="">
        {isOpen ? (
          <Backdrop
            className="h-[100vh]"
            sx={{ color: "#fff", zIndex: 3 }}
            open={isOpen}
            onClick={(e) => {
              setOpen(false);
            }}
          ></Backdrop>
        ) : (
          <div></div>
        )}
        <ul className="gap-x-10 md:flex items-center font-medium hidden ">
          <li>Home</li>
          <li>Services</li>
          <li>Partners</li>
          <li>Why choose us</li>
          <li>Testimonials</li>
          <li>FAQs</li>
        </ul>
      </div>
      <div className="md:flex hidden">
        <p className="bg-gold px-4 py-2 rounded-lg cursor-pointer ">Download now</p>
      </div>
      <div className="w-[40px] h-[40px] md:hidden  fixed z-[50] right-[30px] top-[20px]">
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          color={isOpen ? "#fff" : "#a20000"}
        />
      </div>
      <motion.div
        animate={{
          display: "fixed",
          top: 0,
          right: !isOpen ? -180 : 0,
        }}
        className="w-[150px] md:hidden fixed top-0 right-0 text-[14px] z-[20]  h-[100vh] bg-[#a20000] rounded-l-[30px] px-5 text-white pt-[100px] text-center"
      >
        <div className="">
          <ul className="flex flex-col gap-y-5  items-center font-medium ">
            <li>Home</li>
            <li>Services</li>
            <li>Partners</li>
            <li>Why choose us</li>
            <li>Testimonials</li>
            <li>FAQs</li>
          </ul>
        </div>
        <div className="my-[30px]">
          <p className="bg-gold px-4 py-3 text-[#a20000] text-[15px] leading-4  rounded-lg cursor-pointer ">
            Download
          </p>
        </div>
      </motion.div>
    </Container>
  );
};

export default NavBar;
