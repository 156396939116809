import React, { ReactElement } from "react";

const Container = ({
  children,
  className,
}: {
  children?: ReactElement | ReactElement[];
  className?: string;
}) => {
  return <div className={`px-[15px] md:px-[100px] ${className}`}>{children}</div>;
};

export default Container;
