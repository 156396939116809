import React from "react";
import Container from "../../components/Container";
import Slide from "@mui/material/Slide";
import MTNLogo from "../../assets/billers/mtn.png";
import GLOLogo from "../../assets/billers/glo.png";
import NineMobileLogo from "../../assets/billers/9mobile.png";
import AirtelLogo from "../../assets/billers/airtel.png";
import LongBiller from "../../assets/billers/long_billers.png";
import "../../App.css";

const OurBillers = () => {
  return (
    <div className=" h-[120px] md:h-[200px] py-[20px] bg-black overflow-clip w-[100vw] relative">
      <Container className="text-center w-full h-full flex items-center overflow-hidden">
        <div className="carousel-track ">
          <div className="flex h-[100px]  items-center">
            <div className="h-[100px] w-[150px]">
              <img src={MTNLogo} className="object-contain w-full h-full" />
            </div>
            <div className="h-[100px] w-[150px]">
              <img src={GLOLogo} className="object-contain w-full h-full" />
            </div>
            <div className="h-[100px] w-[150px]">
              <img src={NineMobileLogo} className="object-contain w-full h-full" />
            </div>
            <div className="h-[100px] w-[150px]">
              <img src={AirtelLogo} className="object-contain w-full h-full" />
            </div>
            <div className="h-[100px] w-[3500px]">
              <img src={LongBiller} className="object-contain w-full h-full" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurBillers;
