import React, { ReactElement } from "react";

const OurServiceItems = ({
  bottomIcon,
  title,
  body,
}: {
  bottomIcon?: string;
  title: string;
  body: string;
}) => {
  // return (
  //   <div className="w-full h-full relative flex overflow-clip">
  //     <img src={bottomIcon} className="absolute object-contain w-full h-full" />
  //     <div className="absolute w-full h-full p-[30px] font-sans text-white">
  //       <p className="border-b-[1px] border-white py-2">{title}</p>
  //       <div className="p-5 text-base">{body}</div>
  //     </div>
  //   </div>
  // );
  return (
    <div className="w-full h-[300px]  relative font-sans text-white">
      <div className="w-[95%] h-[95%] border-[2px] border-white  rounded-[50px]">
        <p className="border-b-[1px] border-white py-[25px] px-[25px]">{title}</p>
        <div className="absolute w-full h-full p-[25px]  font-sans text-white">
          <div className=" text-base">{body}</div>{" "}
        </div>
      </div>
      <div className="absolute right-0 bottom-0 w-[120px] h-[120px] rounded-full bg-brown p-[20px]">
        <img src={bottomIcon} className=" object-contain w-full h-full" />
      </div>
    </div>
  );
};

export default OurServiceItems;
