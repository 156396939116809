import React from "react";
import Container from "../../components/Container";
import CountUp from "react-countup";
import { MdGroups } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaHandshakeAngle } from "react-icons/fa6";
import { MdOutlineNetworkWifi } from "react-icons/md";

const Growth = () => {
  return (
    <div className="bg-lightGold w-full ">
      <Container className="text-center pt-[50px]">
        <p className="font-[Bestigia] text-[30px]">Our Growth</p>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-x-10 gap-y-5 py-[40px]">
          <div className="border-red-500 border-[1px] rounded-lg h-[150px] md:h-[200px] flex-1 flex flex-col gap-y-1 items-center p-5">
            <MdGroups size={40} className="text-red-500" />

            <p className="text-[22px] md:text-[40px] font-bold">
              <CountUp start={0} end={2000} delay={1} /> +
            </p>
            <p className="text-[15px] md:text-[25px]">Customers</p>
          </div>
          <div className="border-red-500 border-[1px] rounded-lg h-[150px] md:h-[200px] flex-1 flex flex-col gap-y-1 items-center p-5">
            <IoMdCheckmarkCircleOutline size={40} className="text-red-500" />

            <p className="text-[22px] md:text-[40px] font-bold">
              <CountUp start={0} end={180} delay={1} /> K+
            </p>
            <p className="text-[15px] md:text-[25px]">Transactions</p>
          </div>
          <div className="border-red-500 border-[1px] rounded-lg h-[150px] md:h-[200px] flex-1 flex flex-col gap-y-1 items-center p-5">
            <FaHandshakeAngle size={40} className="text-red-500" />

            <p className="text-[22px] md:text-[40px] font-bold">
              <CountUp start={0} end={50} delay={1} /> +
            </p>
            <p className="text-[15px] md:text-[25px]">Billers</p>
          </div>
          <div className="border-red-500 border-[1px] rounded-lg h-[150px] md:h-[200px] flex-1 flex flex-col gap-y-1 items-center p-5">
            <MdOutlineNetworkWifi size={40} className="text-red-500" />

            <p className="text-[22px] md:text-[40px] font-bold">
              <CountUp start={0} end={5} delay={1} /> TB+
            </p>
            <p className="text-[15px] md:text-[25px]">of Data Sold</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Growth;
