import React from "react";
import Container from "../../components/Container";
import OurServiceItems from "../../components/OurServiceItems";
import AirtimeServiceIcon from "../../assets/i_airtime.png";
import DataServiceIcon from "../../assets/i_data.png";
import ElectricServiceIcon from "../../assets/i_electric.png";
import CableServiceIcon from "../../assets/i_cable.png";
import InternetServiceIcon from "../../assets/i_internet.png";
import ExamServiceIcon from "../../assets/i_exam.png";
import BettingServiceIcon from "../../assets/i_betting.png";

const Services = () => {
  return (
    <div className=" w-full font-[Bestigia] text-gold bg-brown md:bg-services bg-cover bg-no-repeat bg-center py-[50px] overflow-clip">
      <Container className="flex flex-col items-center h-full">
        <p className="text-[30px]">Our Services</p>
        <div className="grid grid-cols-1 md:grid-cols-4 w-full h-full py-10 md:gap-y-[50px] gap-y-[20px] gap-x-5">
          <OurServiceItems
            bottomIcon={AirtimeServiceIcon}
            title="Airtime Topup"
            body="Mobile VTU airtime for all Network (MTN, GLO, Airtel and 9Mobile)"
          />
          <OurServiceItems
            bottomIcon={DataServiceIcon}
            title="Data Subscription"
            body="Data Subscription for all Networks (MTN, Glo, Airtel and 9Mobile)"
          />
          <OurServiceItems
            bottomIcon={ElectricServiceIcon}
            title="Electricity Bills"
            body="All Electricity bill payment (KEDCO,EKEDC,IBEDC,AEDC, e.t.c)"
          />
          <OurServiceItems
            bottomIcon={CableServiceIcon}
            title="Cable TV"
            body="Cable TV Subscription (DSTV, GOTV, Startimes)"
          />
          <OurServiceItems
            bottomIcon={InternetServiceIcon}
            title="Internet Services"
            body="Internet Data for all Network (Spectrnet and Smile)"
          />
          <OurServiceItems
            bottomIcon={ExamServiceIcon}
            title="Exam Pin"
            body="Exam Result pins (WAEC, NECO, NABTeb)"
          />
          <OurServiceItems
            bottomIcon={BettingServiceIcon}
            title="Betting Topup"
            body="Topping of your favourite Sport Betting Wallet"
          />
        </div>
      </Container>
    </div>
  );
};

export default Services;
